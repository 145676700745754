<template>
    <div>
        <categories-update-form
            v-model="value"
            :errors="errors"
            ref="categoriesUpdateForm"
        />

        <div class="flex justify-content-between">
            <div class="block">
                <Button
                    @click="prevPage()"
                    label="Zurück"
                    class="p-button-secondary p-button-sm cursor-pointer"
                />
            </div>
            <div class="block">
                <Button
                    @click="nextPage()"
                    label="Weiter"
                    class="p-button-sm cursor-pointer"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import TreeTable from 'primevue/treetable';
    import Column from 'primevue/column';
    import Errors from "@/components/form/Errors";
    import SelectButton from 'primevue/selectbutton';
    import Dropdown from 'primevue/dropdown';
    import Steps from 'primevue/steps';
    import InputText from "primevue/inputtext/InputText";
    import Value from "@/mixin/Value";
    import CategoriesUpdateForm from "@/components/data/matchingEntity/CategoriesUpdateForm";

    export default {
        mixins: [Value],
        components: {
            CategoriesUpdateForm,
            Button,
            Errors,
            TreeTable,
            Column,
            SelectButton,
            Dropdown,
            Steps,
            InputText,
        },
        methods: {
            nextPage() {
                this.$emit('input', this.value);
                this.$emit('nextPage', {});
            },
            prevPage() {
                this.$emit('prevPage', {});
            }
        },
    }
</script>
